import React, { ChangeEvent } from 'react';
import classnames from 'classnames';

import s from './Checkbox.module.scss';

type Props = {
  id: string;
  name?: string;
  label?: string;
  type?: 'checkbox' | 'radio';
  checked?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  error?: any;
  touched?: any;
  onChange?: (e: ChangeEvent<any>) => void;
};

export const Checkbox = ({
  id,
  name,
  label,
  type,
  checked,
  children,
  disabled,
  required,
  error,
  touched,
  onChange,
}: Props) => {
  // console.log('render', checked);
  return (
    <div className={classnames(s.checkbox, { [s.radio]: type === 'radio' })}>
      <input
        id={id}
        name={name}
        className={s.checkbox__input}
        type={type || 'checkbox'}
        defaultChecked={checked}
        disabled={disabled}
        onChange={onChange}
        // required={required}
        aria-invalid={error && touched ? true : false}
      />
      <label htmlFor={id} className={s.checkbox__label}>
        <span>{label || children}</span>
      </label>
      {error && touched && (
        <p className={s.checkbox__message} id={`${id}_error`} role="alert">
          {error}
        </p>
      )}
    </div>
  );
};
