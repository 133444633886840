import React from 'react';
import cn from 'classnames';

import { IImage } from 'utils/types';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { SliceHero } from 'components/hero/SliceHero';

import s from './Offices.module.scss';

type Props = {
  isSlice?: boolean;
  title?: string;
  image?: IImage;
  display?: string;
  children: React.ReactNode;
};

export const Offices = ({ isSlice, title, image, display, children }: Props) => {
  const content = (
    <div className={s.offices__list}>
      {React.Children.map(children, (component: JSX.Element, i) =>
        React.cloneElement(component, {
          index: i + 1,
          featured: display === 'two-columns-featured' && i === 0,
        })
      )}
    </div>
  );

  const container = (
    <BackgroundSection>
      <section className={s.offices__segment}>
        <div className={s.offices__container}>
          <SliceHero title={title} image={image} flush />
          {content}
        </div>
      </section>
    </BackgroundSection>
  );

  return <div className={cn(s.offices, s[display])}>{isSlice ? container : content}</div>;
};
