import React from 'react';
import classnames from 'classnames';
import { TimelineMax } from 'gsap';
import { ChevronRight } from 'components/assets';

import s from './Gallery.module.scss';

type Props = {
  children: React.ReactNode;
  title?: string;
  isSlice?: boolean;
  isSplitPage?: boolean;
  isArticle?: boolean;
};

export const Gallery = ({ children, title, isSlice, isSplitPage, isArticle }: Props) => {
  const listEl = React.useRef<HTMLUListElement>(null);
  const [index, setIndex] = React.useState(0);
  const [size, setSize] = React.useState('main');

  const total = React.Children.count(children);
  const ease = 'Power4.easeInOut';
  const speed = 0.5;

  const classes = classnames(s.gallery, {
    [s.split]: isSplitPage,
    [s.article]: isArticle,
    [s.full]: !isSplitPage && !isArticle,
  });

  const previousSlide = () => {
    if (!listEl.current) {
      return;
    }

    const newIndex = index - 1 < 0 ? total - 1 : index - 1;
    const els = Array.from(listEl.current.children);
    const t = new TimelineMax();

    t.set([els[index], els[newIndex]], { opacity: 1 });
    t.fromTo(els[index], speed, { x: '0%' }, { x: '100%', ease }, 0);
    t.fromTo(els[newIndex], speed, { x: '-100%' }, { x: '0%', ease }, 0);

    setIndex(newIndex);
  };

  const nextSlide = () => {
    if (!listEl.current) {
      return;
    }

    const newIndex = index + 1 > total - 1 ? 0 : index + 1;
    const els = Array.from(listEl.current.children);
    const t = new TimelineMax();

    t.set([els[index], els[newIndex]], { opacity: 1 });
    t.fromTo(els[index], speed, { x: '0%' }, { x: '-100%', ease }, 0);
    t.fromTo(els[newIndex], speed, { x: '100%' }, { x: '0%', ease }, 0);

    setIndex(newIndex);
  };

  const updateSize = (e) => {
    if (e.matches) {
      setSize('main');
    } else {
      setSize('square');
    }
  };

  React.useEffect(() => {
    // console.log(index);
  }, [index]);

  React.useEffect(() => {
    const mqString = !isSplitPage
      ? '(min-width: 720px)'
      : '(min-width: 720px) and (max-width: 1080px)';
    let mql = window.matchMedia(mqString);

    updateSize(mql);
    mql.addListener(updateSize);

    return () => {
      mql.removeListener(updateSize);
    };
  }, []);

  const content = (
    <section className={s.gallery__wrap} aria-roledescription="carousel" aria-live="polite">
      <div className={s.gallery__constraint}>
        <div className={s.gallery__header}>
          {title && <h3 className={s.gallery__title}>{title}</h3>}
          <div className={s.gallery__controls}>
            <button
              className={s.gallery__previous}
              onClick={previousSlide}
              aria-label="Previous image"
            >
              <ChevronRight />
            </button>
            <div
              className={s.gallery__position}
              aria-label={`Currently showing image ${index + 1} of ${total}`}
            >
              {index + 1} / {total}
            </div>
            <button className={s.gallery__next} onClick={nextSlide} aria-label="Next image">
              <ChevronRight />
            </button>
          </div>
        </div>
      </div>
      <div className={s.gallery__carousel}>
        <div className={s.gallery__aspect} />
        <ul className={s.gallery__list} ref={listEl}>
          {React.Children.map(children, (component: JSX.Element, i) =>
            React.cloneElement(component, {
              selected: i === index,
              size: size,
              index: i,
              total: total,
            })
          )}
        </ul>
      </div>
    </section>
  );

  return (
    <div className={classes}>
      <div className={s.gallery__segment}>
        <div className={s.gallery__container}>
          <div className={s.gallery__row}>
            <div className={s.gallery__col}>{content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
