import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';

import { IImage } from 'utils/types';

import s from './Author.module.scss';

type Props = {
  name?: string;
  position?: string;
  image?: IImage;
  imageComponent?: React.ReactNode;
  inline?: boolean;
};

export const Author = ({ name, position, image, imageComponent, inline }: Props) => {
  if (!name) {
    return null;
  }

  return (
    <div className={classnames(s.author, { [s.inline]: inline })}>
      {(image || imageComponent) && (
        <div className={s.author__image}>
          {image && (
            <Image
              className={s.author__img}
              src={image.url}
              width={image.width}
              height={image.height}
            />
          )}
          {imageComponent && imageComponent}
        </div>
      )}
      <div className={s.author__text}>
        <p className={s.author__name}>{name}</p>
        {position && (
          <div className={s.author__position}>
            <p>{position}</p>
          </div>
        )}
      </div>
    </div>
  );
};
