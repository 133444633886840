import React from 'react';

type Props = {
  data: any;
  alt?: string;
  className?: string;
  preferedVersion?: string[];
  onLoad?: () => any;
};

type ImageHelper = {
  getPreferedVersion: (
    data: any,
    preferedVersion?: string[]
  ) => React.PropsWithoutRef<JSX.IntrinsicElements['img']>;
};

interface IImage extends React.FC<Props> {
  getPreferedVersion: typeof getPreferedVersion;
}

const Image: IImage = ({ data, preferedVersion, alt, ...rest }) => {
  if ((data as any).url) {
    return <img alt={''} src={(data as any).url} {...rest} />;
  }

  const version = Image.getPreferedVersion(data, preferedVersion);

  return <img alt={(data.main && data.main.alt) || alt || ''} {...version} {...rest} />;
};

const getPreferedVersion = (data: any, preferedVersion: string[] = []) => {
  const defaultVersion = {
    src:
      'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAyAAAAJYCAMAAACtqHJCAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGMjY1RDYwN0UyQkMxMUU5QTBCMUUxMDNENjBCMjM2MyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpGMjY1RDYwOEUyQkMxMUU5QTBCMUUxMDNENjBCMjM2MyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkYyNjVENjA1RTJCQzExRTlBMEIxRTEwM0Q2MEIyMzYzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkYyNjVENjA2RTJCQzExRTlBMEIxRTEwM0Q2MEIyMzYzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+lSXnmgAAAAZQTFRFzc3NAAAAnbOuFAAAAetJREFUeNrswQENAAAAwqD3T20PBxQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8mAADAFXBAAG714VaAAAAAElFTkSuQmCC',
    alt: 'no-image',
  };

  if (!data.hasImage) {
    return defaultVersion;
  }

  return (
    preferedVersion.reduce((prev, curr) => {
      if (prev) {
        return prev;
      }

      const img = data.get(curr);

      if (img && img.url) {
        return {
          src: img.url,
          ...img.dimensions,
        };
      }

      return undefined;
    }, undefined) || {
      src: (data.main || { url: '' }).url,
      ...(data.main || { dimensions: undefined }).dimensions,
    }
  );
};

Image.getPreferedVersion = getPreferedVersion;

export { Image };
