import React from 'react';
import classnames from 'classnames';
import { TweenLite } from 'gsap';

import { ILink } from 'utils/types';
import { Button } from 'components/button/Button';
import { TextButton } from 'components/button/TextButton';
import { ChevronRight } from 'components/assets';
import { Warning } from './Warning';

import s from './Catalog.module.scss';

type Props = {
  title?: string;
  warning?: boolean;
  hasRems?: boolean;
  infoLink?: ILink;
  remsLink?: ILink;
  link?: ILink;
  active?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

export const Product = ({
  title,
  warning,
  hasRems,
  infoLink,
  remsLink,
  link,
  active,
  onClick,
  children,
}: Props) => {
  const answerEl = React.useRef<HTMLDivElement>(null);
  const wrapEl = React.useRef<HTMLDivElement>(null);
  const [alert, setAlert] = React.useState(false);
  const [externalLink, setExternalLink] = React.useState<ILink>(infoLink || remsLink);

  React.useEffect(() => {
    if (!answerEl.current || !wrapEl.current) {
      return;
    }

    const answerHeight = wrapEl.current.offsetHeight;

    if (active) {
      TweenLite.to(answerEl.current, 0.4, { height: answerHeight });
    } else {
      TweenLite.to(answerEl.current, 0.4, { height: 0 });
    }

    return () => {};
  }, [active]);

  return (
    <div className={classnames(s.product, { [s.active]: active })}>
      <button
        className={s.product__header}
        onClick={onClick}
        tabIndex={0}
        aria-label={`${active ? 'Open' : 'Close'} ${title}`}
      >
        <div className={s.product__text}>
          <div className={s.product__title}>{title}</div>
          <div className={s.product__warning}>{warning && 'Product contains boxed warning'}</div>
          <div className={s.product__rems}>{hasRems && 'REMS'}</div>
        </div>
        <div className={s.product__action}>
          <div className={s.product__toggle} tabIndex={-1}>
            <ChevronRight />
          </div>
        </div>
      </button>
      <div className={s.product__content} ref={answerEl}>
        <div className={s.product__body} ref={wrapEl}>
          <table className={s.table}>
            <thead className={s.table__head}>
              <tr className={s.table__row}>
                <th className={s.table__header}>Product</th>
                <th className={s.table__header}>NDC#</th>
                <th className={s.table__header}>Strength</th>
                <th className={s.table__header}>PKG size</th>
                <th className={s.table__header}>GCN</th>
                <th className={s.table__header}>GCN SEQ#</th>
              </tr>
            </thead>
            <tbody className={s.table__body}>{children}</tbody>
          </table>
          <div className={s.product__footer}>
            <div className={s.product__external}>
              {hasRems && (
                <TextButton
                  onClick={() => {
                    setAlert(true);
                    setExternalLink(remsLink);
                  }}
                  tabIndex={active ? 0 : -1}
                >
                  <span className={s.product__remsLong}>
                    Risk Evaluation and Mitigation Strategy (REMS)
                  </span>
                  <span className={s.product__remsShort}>REMS</span>
                </TextButton>
              )}
              <TextButton
                onClick={() => {
                  setAlert(true);
                  setExternalLink(infoLink);
                }}
                tabIndex={active ? 0 : -1}
              >
                Prescribing info {warning && 'with boxed warning'}
              </TextButton>
            </div>
            <div className={s.product__details}>
              <Button
                color="dark"
                small
                link={link}
                tabIndex={active ? 0 : -1}
                aria-label="Product details"
              >
                Details
              </Button>
            </div>
          </div>
        </div>
      </div>
      {alert && (
        <Warning
          title="Please note"
          footer={externalLink?.href && <TextButton href={externalLink.href}>Continue</TextButton>}
          onClose={() => setAlert(false)}
        >
          <div>
            <p>
              You are now leaving this page to visit a 3rd party website not operated by Alvogen.
              Please review the new sites’ Privacy Policy &amp; Terms and Conditions since they may
              differ from ours.
            </p>
          </div>
        </Warning>
      )}
    </div>
  );
};
