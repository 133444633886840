import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { BackgroundSection } from 'components/background/BackgroundSection';
import { Tagline } from 'components/tagline/Tagline';
import { Signup } from 'components/form/Signup';
import { Fade } from 'components/animations/Fade';

import s from './Newsletter.module.scss';

type Props = {
  tagline?: string;
  title: string;
  isSlice?: boolean;
};

export const Newsletter = ({ tagline, title, isSlice }: Props) => {
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.newsletter__row}>
          <div className={s.newsletter__content}>
            <Fade isVisible={isVisible}>
              <Tagline text={tagline} />
            </Fade>

            <div className={s.newsletter__header}>
              <Fade isVisible={isVisible} delay={0.15}>
                <h2 className={s.newsletter__title}>{title}</h2>
              </Fade>
            </div>

            <Fade isVisible={isVisible} delay={baseDelay}>
              <Signup
                id="EMAIL"
                name="EMAIL"
                placeholder="Enter your email address"
                label={title}
                hideLabel
              />
            </Fade>
          </div>
          <div className={s.newsletter__figure}>
            <Fade isVisible={isVisible} delay={baseDelay}>
              <div className={s.newsletter__image} />
            </Fade>
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  if (isSlice) {
    return (
      <BackgroundSection color="dark">
        <div className={s.newsletter}>
          <div className={s.newsletter__segment}>
            <div className={s.newsletter__container}>{content}</div>
          </div>
        </div>
      </BackgroundSection>
    );
  }

  return <div className={s.newsletter}>{content}</div>;
};
