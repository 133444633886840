import React, { ChangeEvent } from 'react';

import s from './Textbox.module.scss';

type Props = {
  id: string;
  name: string;
  label: string;
  placeholder?: string;
  type?: 'text' | 'email' | 'textarea' | 'number';
  disabled?: boolean;
  value?: string;
  required?: boolean;
  error?: any;
  touched?: any;
  onChange?: (e: ChangeEvent<any>) => void;
};

export const Textbox = ({
  id,
  name,
  label,
  type,
  disabled,
  onChange,
  placeholder,
  value,
  required,
  error,
  touched,
  ...rest
}: Props) => {
  const TagName = type === 'textarea' ? 'textarea' : 'input';

  // console.log(required, error);

  return (
    <div className={s.textbox}>
      <label htmlFor={id} className={s.textbox__label}>
        <span>{label}</span>
      </label>
      <TagName
        id={id}
        name={name}
        className={s.textbox__input}
        type={type || 'text'}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        // required={required}
        aria-required={required}
        aria-invalid={error && touched ? true : false}
        aria-describedby={`${id}_error`}
        {...rest}
      />
      {error && touched && (
        <p className={s.textbox__message} id={`${id}_error`} role="alert">
          {error}
        </p>
      )}
    </div>
  );
};
