import React from 'react';
import classnames from 'classnames';

import { ILink } from 'utils/types';
import { Tagline } from 'components/tagline/Tagline';
import { IconButton } from 'components/button/IconButton';
import { TextButton } from 'components/button/TextButton';

import s from './Contact.module.scss';

type Props = {
  title?: string;
  name?: string;
  text?: React.ReactNode;
  phone?: string;
  email?: string;
  linkedin?: ILink;
  link?: ILink;
  linkTagline?: string;
  linkTitle?: string;
  isSlice?: boolean;
  isSplitPage?: boolean;
};

export const Contact = ({
  title,
  name,
  text,
  phone,
  linkedin,
  email,
  link,
  linkTagline,
  linkTitle,
  isSlice,
  isSplitPage,
}: Props) => {
  const classes = classnames(s.contact, {
    [s.split]: isSplitPage,
    [s.full]: !isSplitPage,
  });

  const content = (
    <div className={s.contact__row}>
      <div className={s.contact__layout}>
        <Tagline />
        {title && (
          <div className={s.contact__header}>
            <h2 className={s.contact__title}>{title}</h2>
          </div>
        )}
        <div className={s.contact__row}>
          <div className={s.contact__content}>
            {name && <div className={s.contact__name}>{name}</div>}
            {text && <div className={s.contact__copy}>{text}</div>}
            {phone && (
              <div className={s.contact__phone} x-ms-format-detection="none">
                Phone {phone}
              </div>
            )}
            {email && (
              <div className={s.contact__button}>
                <TextButton href={`mailto:${email}`} aria-label="Contact email">
                  Email
                </TextButton>
              </div>
            )}
            {linkedin && (
              <div className={s.contact__button}>
                <TextButton {...linkedin}>Connect on Linkedin</TextButton>
              </div>
            )}
          </div>
          {link && (
            <div className={s.contact__link}>
              <div className={s.contact__linkbox}>
                {linkTagline && <div className={s.contact__linkboxTagline}>{linkTagline}</div>}
                {linkTitle && <h3 className={s.contact__linkboxTitle}>{linkTitle}</h3>}
                <div className={s.contact__linkboxButton}>
                  <IconButton color="mega" {...link} aria-label={linkTagline || linkTitle} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (!isSlice) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <div className={classes}>
      <div className={s.contact__segment}>
        <div className={s.contact__container}>
          <div className={s.contact__row}>
            <div className={s.contact__col}>{content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
