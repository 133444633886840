import React, { ChangeEvent } from 'react';
import classnames from 'classnames';
import { ChevronRight } from 'components/assets';
import { config } from 'utils/config';

import s from './Signup.module.scss';

type Props = {
  id: string;
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (e: ChangeEvent<any>) => void;
  hideLabel?: boolean;
};

export const Signup = ({
  id,
  name,
  label,
  disabled,
  onChange,
  placeholder,
  value,
  hideLabel,
  ...rest
}: Props) => {
  return (
    <div className={s.signup}>
      <form action={config.newsletter} method="post" target="_blank">
        <label htmlFor={id} className={classnames(s.signup__label, { [s.hidden]: hideLabel })}>
          <span>{label}</span>
        </label>
        <div className={s.signup__wrap}>
          <input
            id={id}
            name={name}
            className={s.signup__input}
            type="email"
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            aria-label={label}
            {...rest}
          />
          <button type="submit" className={s.signup__button} aria-label="Submit subscription">
            <ChevronRight />
          </button>
        </div>
      </form>
    </div>
  );
};
