import React from 'react';

import { ILink } from 'utils/types';
import { TextButton } from 'components/button/TextButton';
import { Fade } from 'components/animations/Fade';
import {
  AlvotechBasket,
  AlvotechBiosimilar,
  AlvotechCosmetics,
  AlvotechEmploeeys,
  AlvotechGenericProjects,
  AlvotechGreen,
  AlvotechHandPill,
  AlvotechKeyGrowth,
  AlvotechManufacturinCenters,
  AlvotechMap,
  AlvotechMedical,
  AlvotechMicroscope,
  AlvotechMolecule,
  AlvotechNetwork,
  AlvotechPipeline,
  AlvotechPlayground,
  AlvotechAward,
  AlvotechBiosGenerics,
  AlvotechCapacity,
  AlvotechCompany,
  AlvotechDna,
  AlvotechInvestment,
  AlvotechManufacturing,
  AlvotechPeople,
  AlvotechPills,
  AlvotechProfessional,
  AlvotechSquareMeter,
} from 'components/assets';

import s from './ColumnSection.module.scss';

type Props = {
  index?: number;
  title?: string;
  text?: React.ReactNode;
  link?: ILink;
  label?: string;
  isVisible?: boolean;
  delay?: number;
  numbered?: boolean;
  icon?: string | null;
  color?: 'brand' | 'white' | 'dark' | string;
};

export const ColumnSectionItem = ({
  title,
  text,
  index,
  link,
  label,
  isVisible,
  delay,
  numbered,
  icon = '',
  color,
}: Props) => {
  const hasIcon = icon && icon.length > 0;
  // const iconName = hasIcon && (icon as string).replace(/^\w/, (c) => c.toUpperCase());
  // const TagName = `Alvotech${iconName}`;

  // console.log(TagName);

  const setIcon = () => {
    switch (icon && icon.toLowerCase().replace(/\s/g, '')) {
      case 'basket':
        return <AlvotechBasket />;
      case 'biosimilar':
        return <AlvotechBiosimilar />;
      case 'cosmetics':
        return <AlvotechCosmetics />;
      case 'emploeeys':
        return <AlvotechEmploeeys />;
      case 'genericprojects':
        return <AlvotechGenericProjects />;
      case 'green':
        return <AlvotechGreen />;
      case 'handpill':
        return <AlvotechHandPill />;
      case 'keygrowth':
        return <AlvotechKeyGrowth />;
      case 'manufacturincenters':
        return <AlvotechManufacturinCenters />;
      case 'map':
        return <AlvotechMap />;
      case 'medical':
        return <AlvotechMedical />;
      case 'microscope':
        return <AlvotechMicroscope />;
      case 'molecule':
        return <AlvotechMolecule />;
      case 'network':
        return <AlvotechNetwork />;
      case 'pipeline':
        return <AlvotechPipeline />;
      case 'playground':
        return <AlvotechPlayground />;
      case 'award':
        return <AlvotechAward />;
      case 'biosgenerics':
        return <AlvotechBiosGenerics />;
      case 'capacity':
        return <AlvotechCapacity />;
      case 'company':
        return <AlvotechCompany />;
      case 'dna':
        return <AlvotechDna />;
      case 'investment':
        return <AlvotechInvestment />;
      case 'manufacturing':
        return <AlvotechManufacturing />;
      case 'people':
        return <AlvotechPeople />;
      case 'pills':
        return <AlvotechPills />;
      case 'professional':
        return <AlvotechProfessional />;
      case 'squaremeter':
        return <AlvotechSquareMeter />;
    }
  };

  return (
    <Fade isVisible={isVisible} delay={delay}>
      <div className={s.item}>
        {(numbered || hasIcon) && (
          <div className={s.item__decor}>
            {hasIcon && setIcon()}
            {numbered && <div className={s.item__counter} />}
          </div>
        )}
        <div className={s.item__layout}>
          <div className={s.item__content}>
            {title && <h3 className={s.item__title}>{title}</h3>}
            {text && (
              <div className={s.item__row}>
                <div className={s.item__text}>
                  <div className={s.item__copy}>{text}</div>
                </div>
              </div>
            )}
          </div>
          {link && (
            <div className={s.item__link}>
              <TextButton {...link} color={color} staticColor>
                {label || 'Learn more'}
              </TextButton>
            </div>
          )}
        </div>
      </div>
    </Fade>
  );
};
