import React from 'react';
import classnames from 'classnames';

import s from './Quote.module.scss';

type Props = {
  isSlice?: boolean;
  isSplitPage?: boolean;
  isArticle?: boolean;
  text: React.ReactNode;
  attribution?: React.ReactNode;
  background?: boolean;
  isBlock?: boolean;
};

export const Quote = ({
  text,
  attribution,
  isSlice,
  isSplitPage = false,
  isArticle,
  isBlock = false,
}: Props) => {
  const classes = classnames(s.quote, {
    [s.split]: isSplitPage,
    [s.article]: isArticle,
    [s.full]: !isSplitPage && !isArticle && !isBlock,
    [s.block]: !!isBlock,
  });

  const hasAttribution = React.Children.count(attribution) > 0;

  const content = (
    <figure className={s.quote__figure}>
      <div className={s.quote__content}>
        {!!text && (
          <>
            <div className={s.quote__quotewrap}>
              <blockquote className={s.quote__blockquote}>{text}</blockquote>
            </div>
            {hasAttribution && (
              <figcaption className={s.quote__attribution}>{attribution}</figcaption>
            )}
          </>
        )}
        {!text && attribution && <div>{attribution}</div>}
      </div>
    </figure>
  );

  if (!isSlice) {
    return (
      <div className={classes}>
        <div className={s.quote__row}>
          <div className={s.quote__indent}>{content}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes}>
      <div className={s.quote__segment}>
        <div className={s.quote__container}>
          <div className={s.quote__row}>
            <div className={s.quote__col}>{content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
