import React from 'react';
import cn from 'classnames';
import { BackgroundSection } from 'components/background/BackgroundSection';

import s from './Pipeline.module.scss';

type Props = {
  title?: React.ReactNode;
  text?: React.ReactNode;
  footnotes?: React.ReactNode;
  steps?: string[];
  children: React.ReactNode;
};

export const Pipeline = ({ title, text, footnotes, steps, children }: Props) => {
  return (
    <BackgroundSection>
      <div className={s.pipeline__segment}>
        <div className={s.pipeline__container}>
          {title && <div className={s.pipeline__title}>{title}</div>}
          {text && (
            <div className={s.pipeline__row}>
              <div className={s.pipeline__text}>{text}</div>
            </div>
          )}

          <div className={s.pipeline__table}>
            <table className={s.table}>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '50%' }} />
                {steps && steps.map((_, index) => <col key={index} style={{ width: '0%' }} />)}
              </colgroup>
              <thead className={s.table__thead}>
                <tr>
                  <th>Product</th>
                  <th>Indication</th>
                  {steps &&
                    steps.map((step, index) => {
                      if (step.includes('Pivotal BA/BE')) {
                        const splitStep = step.split('Pivotal');

                        return (
                          <th key={index} className={s.pipeline__stage}>
                            <span>{splitStep[0]} Pivotal</span> <span>{splitStep[1]}</span>
                          </th>
                        );
                      }

                      return (
                        <th key={index} className={s.pipeline__stage}>
                          <span>{step}</span>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody className={s.table__tbody}>
                {steps
                  ? React.Children.map(children, (child) => {
                      return React.cloneElement(child as any, { steps });
                    })
                  : children}
              </tbody>

              {/* <tbody className={s.table__tbody}>{children}</tbody> */}
            </table>
          </div>

          {footnotes && <div className={s.pipeline__footnotes}>{footnotes}</div>}
        </div>
      </div>
    </BackgroundSection>
  );
};
