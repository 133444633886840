import React from 'react';
import ReactDOM from 'react-dom';

import s from './Catalog.module.scss';

type Props = {
  title?: string;
  footer?: React.ReactNode;
  onClose: () => void;
  children: React.ReactNode;
};

export const Warning = ({ title, footer, onClose, children }: Props) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const hasTitle = title && title.length > 0;

  return ReactDOM.createPortal(
    <div className={s.warning}>
      <div className={s.warning__box}>
        <button className={s.warning__close} onClick={onClose}>
          <span className={s.warning__cross} />
          <span className={s.warning__cross} />
        </button>
        {hasTitle && <h2 className={s.warning__title}>{title}</h2>}
        <div className={s.warning__text}>{children}</div>
        <div className={s.warning__footer}>{footer}</div>
      </div>
    </div>,
    document.body
  );
};
