import React from 'react';

type Props = {
  data: IPrismicEmbed | IPrismicMedia;
};

export const Embed = ({ data, ...rest }: Props) => {
  const createMarkup = () => {
    if (data.type === 'embed') {
      return { __html: (data as IPrismicEmbed).oembed.html };
    } else if (data.type === 'video') {
      return { __html: (data as IPrismicMedia).html };
    }
  };

  return <div dangerouslySetInnerHTML={createMarkup()} {...rest} />;
};
