import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import shuffle from 'lodash/shuffle';

import { BackgroundSection } from 'components/background/BackgroundSection';
import { Fade } from 'components/animations/Fade';

import s from './Infographic.module.scss';

type Props = {
  text?: React.ReactNode;
  title?: string;
  isSlice?: boolean;
  children: React.ReactNode;
};

export const Infographics = ({ title, text, isSlice, children }: Props) => {
  const baseDelay = title ? 0.15 : 0;
  const childCount = React.Children.count(children);
  const childDelays = shuffle(new Array(childCount).map((_, x) => 0.03 * x));

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div>
          {title && (
            <div className={s.infographics__header}>
              <Fade isVisible={isVisible}>
                <h2 className={s.infographics__title}>{title}</h2>
              </Fade>
              {text && (
                <Fade isVisible={isVisible} delay={baseDelay}>
                  <div className={s.infographics__text}>{text}</div>
                </Fade>
              )}
            </div>
          )}
          <div className={s.infographics__list}>
            {React.Children.map(children, (component: JSX.Element, i) =>
              React.cloneElement(component, {
                parentVisible: isVisible,
                delay: baseDelay + childDelays[i],
              })
            )}
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection>
      <div className={s.infographics__segment}>
        <div className={s.infographics__container}>{content}</div>
      </div>
    </BackgroundSection>
  );

  return <div className={s.infographics}>{isSlice ? container : content}</div>;
};
