import React from 'react';
import Image from 'next/image';

import { IImage } from 'utils/types';
import { IconDownload } from 'components/assets';
import { Link } from 'components/link/Link';

import s from './MediaList.module.scss';

type Props = {
  title?: string;
  subline?: string;
  aspect?: string | 'Portrait' | 'Landscape';
  image?: IImage;
};

export const MediaListItem = ({ title, subline, image, aspect = 'Landscape' }: Props) => {
  return (
    <div className={s.item}>
      <Link
        className={s.item__link}
        href={image?.url || undefined}
        target="_blank"
        aria-label={`Open ${title}`}
        download
      >
        {image && (
          <div className={s.item__image}>
            <Image
              className={s.item__img}
              src={image[aspect.toLowerCase()]?.url ?? image.url}
              layout="fill"
            />
            <div className={s.item__icon}>
              <IconDownload />
            </div>
          </div>
        )}
        <div className={s.item__title}>{title}</div>
        <div className={s.item__subline}>{subline}</div>
      </Link>
    </div>
  );
};
