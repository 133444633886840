import React from 'react';
import Image from 'next/image';

import { IImage } from 'utils/types';
import { Figure } from 'components/media/Figure';
import { Fade } from 'components/animations/Fade';

import s from './GalleryBlock.module.scss';

type Props = {
  index?: number;
  image?: IImage;
  isVisible?: boolean;
  delay?: number;
  caption?: React.ReactNode;
};

export const GalleryBlockItem = ({
  image,
  isVisible = false,
  delay = 0,
  index,
  caption,
}: Props) => {
  return (
    <div className={s.item}>
      <div className={s.item__aspect} />
      <Fade isVisible={isVisible} delay={delay}>
        {image && (
          <div className={s.item__image}>
            {image && (
              <Figure className={s.item__figure} caption={caption}>
                <Image
                  src={image.tall?.url ?? image.square?.url ?? image.url}
                  layout="fill"
                  className={s.item__img}
                  alt={image.alt || ''}
                />
              </Figure>
            )}
          </div>
        )}
      </Fade>
    </div>
  );
};
