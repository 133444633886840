import React from 'react';
import { t, Trans } from '@lingui/macro';
import { format } from 'date-fns';

import { ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { ChevronRight, IconCalendar, IconDownload2 } from '@alvogen/shared/components/assets';

import s from './Events.module.scss';

type Props = {
  link: ILink;
  title?: string;
  startDate?: string;
  endDate?: string;
  date?: string;
  pdf: ILink;
  location?: string;
  booth?: string;
  description?: string;
  past?: boolean;
};

export const EventsItem = ({
  link,
  title,
  startDate,
  endDate,
  date,
  pdf,
  location,
  booth,
  description,
  past,
}: Props) => {
  const event = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
CLASS:PUBLIC
DESCRIPTION:${title ? title.replace(/\n/g, '\\n') : ''}${
    description ? '\\n' : ''
  }${description.replace(/\n/g, '\\n')}
DTSTART:${format(Date.parse(startDate || ''), "yyyyMMdd'T'HHmmssX")}
DTEND:${format(Date.parse(endDate || ''), "yyyyMMdd'T'HHmmssX")}
LOCATION:${location ? location.replace(/\n/g, '\\n') : ''}${
    booth ? '\\nBooth ' : ''
  }${booth.replace(/\n/g, '\\n')}
SUMMARY;LANGUAGE=en-us:${title.replace(/\n/g, '\\n')}
END:VEVENT
END:VCALENDAR`;

  const downloadICSFile = () => {
    const element = document.createElement('a');
    const file = new Blob([event], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${title}.ics`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <article className={s.item}>
      <Link className={s.item__link} {...link}>
        <header className={s.item__header}>
          <time dateTime={startDate} className={s.item__date}>
            {date}
          </time>
          <h3 className={s.item__title}>{title}</h3>
        </header>
        <div className={s.item__button}>
          <ChevronRight />
        </div>
      </Link>
      <footer className={s.item__footer}>
        {!past && (!pdf || !pdf.href) && (
          <button onClick={downloadICSFile} className={s.item__action}>
            <IconCalendar /> <Trans>Add to Calendar</Trans>
          </button>
        )}
        {pdf && pdf.href && (
          <Link className={s.item__action} {...pdf}>
            <IconDownload2 /> <Trans>Download PDF</Trans>
          </Link>
        )}
      </footer>
    </article>
  );
};
