import React from 'react';
import classnames from 'classnames';

import { IEmbed, IImage } from 'utils/types';
import { PlayButton } from 'components/button/PlayButton';

import s from './VideoItem.module.scss';

type Props = {
  title?: string;
  embed?: IEmbed;
  placeholder?: IImage;
};

export const VideoItem = ({ title, embed, placeholder }: Props) => {
  const classes = classnames(s.video, {});
  const hasTitle = title && title.length > 0;

  const placeholderImage = placeholder?.url || embed?.thumbnailUrl || undefined;

  return (
    <div className={classes}>
      {embed?.embedUrl ? (
        <figure className={s.video__figure}>
          <div
            className={s.video__aspect}
            style={placeholderImage ? { backgroundImage: `url(${placeholderImage})` } : {}}
          >
            <div className={s.video__embed}>
              <div className={s.video__thumbnail}>
                {placeholderImage && <img src={placeholderImage} alt="" />}
                <PlayButton embed={embed} ariaLabel={`Play video ${title}`} />
              </div>
            </div>
          </div>
          {hasTitle && (
            <figcaption className={s.video__caption}>
              <h3 className={s.video__title}>{title}</h3>
            </figcaption>
          )}
        </figure>
      ) : (
        <pre>Missing video</pre>
      )}
    </div>
  );
};
