import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { TimelineLite } from 'gsap';

import s from './Transition.module.scss';

type Props = {
  tagName?: string;
  children: React.ReactNode;
  delay?: number;
  randomDelay?: boolean;
  disabled?: boolean;
  className?: string;
};

export const Transition = ({
  tagName,
  children,
  delay = 0,
  randomDelay,
  disabled,
  className,
}: Props) => {
  const elRef = React.useRef();
  const [hasRun, setHasRun] = React.useState(false);
  const Tag = (tagName || 'div') as any;

  if (disabled) {
    return <Tag>{children}</Tag>;
  }

  const show = () => {
    const t = new TimelineLite();
    const ease = 'Power3.easeOut';

    if (randomDelay) {
      delay += Math.random() * 0.5;
    }

    t.to(elRef.current, 1, { opacity: 1, y: 0, ease }, delay);
  };

  const runCheck = (isVisible) => {
    if (!hasRun) {
      setHasRun(isVisible);
    }

    if (isVisible || hasRun) {
      show();
    }
  };

  return (
    <VisibilitySensor partialVisibility={true} onChange={runCheck}>
      {({ isVisible }) => (
        <Tag ref={elRef} className={classnames(s.transition, className)}>
          {children}
        </Tag>
      )}
    </VisibilitySensor>
  );
};
