import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import shuffle from 'lodash/shuffle';

import { ILink } from 'utils/types';
import { MegaButton } from 'components/button/MegaButton';
import { Tagline } from 'components/tagline/Tagline';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { Fade } from 'components/animations/Fade';

import s from './GalleryBlock.module.scss';

type Props = {
  isSlice?: boolean;
  index?: number;
  tagline?: string;
  title?: string;
  text?: React.ReactNode;
  link?: ILink;
  label?: string;
  buttonText?: string;
  buttonCaption?: string;
  buttonSubline?: string;
  buttonNumber?: string;
  children: React.ReactNode;
};

export const GalleryBlock = ({
  tagline,
  title,
  text,
  index,
  link,
  label,
  buttonText,
  buttonCaption,
  buttonSubline,
  buttonNumber,
  isSlice,
  children,
}: Props) => {
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);
  const childCount = React.Children.count(children);
  const childDelays = shuffle(new Array(childCount).map((_, x) => 0.03 * x));

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.block__layout}>
          <div className={s.block__contentCol}>
            {tagline && (
              <Fade isVisible={isVisible}>
                <Tagline index={index} text={tagline} />
              </Fade>
            )}
            {title && (
              <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                <h2 className={s.block__title}>{title}</h2>
              </Fade>
            )}
            {text && (
              <Fade isVisible={isVisible} delay={baseDelay}>
                <div className={s.block__row}>
                  <div className={s.block__text}>
                    <div className={s.block__copy}>{text}</div>
                  </div>
                </div>
              </Fade>
            )}
            {link && (
              <Fade isVisible={isVisible} delay={baseDelay + 0.15}>
                <div className={s.block__link}>
                  <MegaButton
                    label={buttonText}
                    caption={buttonCaption}
                    subline={buttonSubline}
                    number={buttonNumber}
                    link={link}
                    ariaLabel={buttonText || label}
                  >
                    {label || 'Read more'}
                  </MegaButton>
                </div>
              </Fade>
            )}
          </div>
          <div className={s.block__imageCol}>
            <div className={s.block__imageGrid}>
              {React.Children.map(children, (component: JSX.Element, i) =>
                React.cloneElement(component, { isVisible, delay: childDelays[i], index: i })
              )}
            </div>
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection>
      <div className={s.block__segment}>
        <div className={s.block__container}>
          <div className={s.block__row}>
            <div className={s.block__col}>{content}</div>
          </div>
        </div>
      </div>
    </BackgroundSection>
  );

  return <div className={s.block}>{isSlice ? container : content}</div>;
};
