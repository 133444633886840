import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { IEmbed, IImage } from 'utils/types';

import { Media } from 'components/media/Media';
import { Tagline } from 'components/tagline/Tagline';
import { Fade } from 'components/animations/Fade';

import s from './Video.module.scss';

type Props = {
  tagline?: string;
  title?: string;
  isSlice?: boolean;
  isSplitPage?: boolean;
  embed?: IEmbed;
  placeholder?: IImage;
  isArticle?: boolean;
};

export const Video = ({
  tagline,
  title,
  isSlice,
  isSplitPage,
  embed,
  placeholder,
  isArticle,
}: Props) => {
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);

  const classes = classnames(s.video, {
    [s.split]: isSplitPage,
    [s.article]: isArticle,
    [s.full]: !isSplitPage && !isArticle,
  });

  const placeholderImage = placeholder?.url || embed?.thumbnailUrl || undefined;

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div>
          {title && (
            <div className={s.video__header}>
              <Fade isVisible={isVisible}>
                <Tagline text={tagline} />
              </Fade>
              <Fade isVisible={isVisible} delay={0.15}>
                <h2 className={s.video__title}>{title}</h2>
              </Fade>
            </div>
          )}
          <div className={s.video__wrap}>
            <Fade isVisible={isVisible} delay={baseDelay}>
              <div className={s.video__row}>
                <div className={s.video__col}>
                  {embed?.embedUrl ? (
                    <div
                      className={s.video__aspect}
                      style={
                        placeholderImage ? { backgroundImage: `url(${placeholderImage})` } : {}
                      }
                    >
                      <div className={s.video__embed}>
                        <Media data={embed as any} isVisible={isVisible} />
                      </div>
                    </div>
                  ) : (
                    <pre>Missing video</pre>
                  )}
                </div>
              </div>
            </Fade>
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  if (isArticle) {
    return (
      <div className={classes}>
        <div className={s.video__articleSegment}>
          <div className={s.video__container}>{content}</div>
        </div>
      </div>
    );
  }

  if (!isSlice || isSplitPage) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <div className={classes}>
      <div className={s.video__segment}>
        <div className={s.video__container}>{content}</div>
      </div>
    </div>
  );
};
