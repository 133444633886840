import React from 'react';
import { t, Trans } from '@lingui/macro';
import { parseISO, parse, isAfter, isBefore } from 'date-fns';

import { ILink } from 'utils/types';
import { EventsItem } from './EventsItem';
import { IEvent } from 'providers/prismic/events';

import s from './Events.module.scss';

type Props = {
  upcomingTitle?: React.ReactNode;
  previousTitle?: React.ReactNode;
  kicker?: React.ReactNode;
  items?: IEvent[];
};

export const Events = ({ upcomingTitle, previousTitle, kicker, items }: Props) => {
  const renderEventItems = (events: any[]) =>
    events?.map((event, index) => (
      <EventsItem
        key={index}
        title={event.title}
        startDate={event.rawStartDate}
        endDate={event.rawEndDate}
        date={event.startDate}
        link={event.altLink ?? event.link}
        pdf={event.investorPresentation}
        location={event.addressString}
        booth={event.booth}
        description={event.seo?.description || ''}
        past={isBefore(Date.parse(event.rawEndDate), new Date())}
      />
    ));

  const upcommingEvents = items
    ?.filter((event) => isAfter(Date.parse(event.rawEndDate), new Date()))
    .reverse();
  const pastEvents = items?.filter((event) => isBefore(Date.parse(event.rawEndDate), new Date()));

  return (
    <section className={s.events}>
      <div className={s.events__container}>
        {upcommingEvents?.length > 0 && (
          <>
            <header className={s.events__header}>
              {kicker && <p className={s.events__tagline}>{kicker}</p>}
              <h2 className={s.events__title}>{upcomingTitle || <Trans>Upcoming events</Trans>}</h2>
            </header>
            <div className={s.events__list}>{renderEventItems(upcommingEvents)}</div>
          </>
        )}
        {pastEvents?.length > 0 && (
          <>
            <header className={s.events__header}>
              <h2 className={s.events__title}>{previousTitle || <Trans>Previous events</Trans>}</h2>
            </header>
            <div className={s.events__list}>{renderEventItems(pastEvents)}</div>
          </>
        )}
      </div>
    </section>
  );
};
