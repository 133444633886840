import React from 'react';
import cn from 'classnames';
import { Trans } from '@lingui/macro';

import { Transition } from 'components/transition/Transition';

import s from './Offices.module.scss';

type Props = {
  index?: number;
  title?: string;
  text?: React.ReactNode;
  address?: React.ReactNode;
  hasAddress?: boolean;
  phone?: string;
  fax?: string;
  email?: string;
  featured?: boolean;
};

export const OfficesItem = ({
  title,
  text,
  address,
  hasAddress,
  index,
  phone,
  fax,
  email,
  featured,
}: Props) => {
  return (
    <Transition className={featured ? s.item__featured : undefined}>
      <div className={cn(s.item, { [s.featured]: featured })}>
        <div className={s.item__layout}>
          <div className={s.item__content}>
            {title && <h3 className={s.item__title}>{title}</h3>}
            {text && <div className={s.item__copy}>{text}</div>}
          </div>
          {hasAddress && <div className={s.item__address}>{address}</div>}
          <div className={s.item__contact}>
            <ul className={s.item__contactList}>
              {phone && (
                <li className={s.item__contactItem}>
                  <span className={s.item__contactLabel}>
                    <Trans>Phone</Trans>:
                  </span>
                  <a className={s.item__contactLink} href={`tel:${phone}`}>
                    {phone}
                  </a>
                </li>
              )}
              {fax && (
                <li className={s.item__contactItem}>
                  <span className={s.item__contactLabel}>
                    <Trans>Fax</Trans>:
                  </span>
                  <a className={s.item__contactLink} href={`fax:${fax}`}>
                    {fax}
                  </a>
                </li>
              )}
              {email && (
                <li className={s.item__contactItem}>
                  <span className={s.item__contactLabel}>
                    <Trans>Email</Trans>:
                  </span>
                  <a className={s.item__contactLink} href={`mailto:${email}`}>
                    {email}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Transition>
  );
};
