import React from 'react';
import dynamic from 'next/dynamic';
import { useBackgroundColor } from 'components/background/Background';

// @ts-ignore
const isServer = !process.browser;
const DynamicGlobe = dynamic(() => import('components/globe/App'));

const clientOnly = <T extends {}>(Component: React.ComponentType<T>) =>
  class ClientOnly extends React.Component<T> {
    state = {
      tick: false,
    };

    timer: NodeJS.Timeout;

    componentDidMount() {
      this.timer = setTimeout(() => {
        this.setState({
          tick: true,
        });
      }, 0);
    }

    componentWillUnmount() {
      clearTimeout(this.timer);
    }

    render() {
      const { tick } = this.state;

      if (isServer || !tick) {
        return null;
      } else {
        return <Component {...this.props as T} />;
      }
    }
  };

type ColorMode = 'dark' | 'light';
type Props = { width?: number; height?: number; animate?: boolean; mode?: ColorMode };

export const Main = clientOnly<Props>(({ width, height, animate, mode }) => {
  const color = useBackgroundColor();
  let colorMode: ColorMode;

  if (mode) {
    colorMode = mode;
  } else {
    colorMode = color === 'dark' ? 'dark' : 'light';
  }

  return (
    <DynamicGlobe width={width} height={height} animate={animate} dark={colorMode === 'dark'} />
  );
});
