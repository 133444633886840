import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';

import { IImage } from 'utils/types';
import { Fade } from 'components/animations/Fade';

import s from './GalleryGrid.module.scss';

type Props = {
  size?: 'square' | 'tall' | string;
  image: IImage;
  isVisible?: boolean;
  delay?: number;
};

export const GalleryGridItem = ({
  image,
  size = 'square',
  isVisible = false,
  delay = 0,
}: Props) => {
  return (
    <div className={classnames(s.item, { [s.tall]: size === 'tall' })}>
      <div className={s.item__aspect} />
      <Fade isVisible={isVisible} delay={delay}>
        <div className={s.item__image}>
          {image && (
            <Image src={image[size]?.url || image.url} layout="fill" className={s.item__img} />
          )}
        </div>
      </Fade>
    </div>
  );
};
