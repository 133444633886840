import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import Image from 'next/image';

import { IEmbed, IImage, ILink } from 'utils/types';
import { Figure } from 'components/media/Figure';
import { MegaButton } from 'components/button/MegaButton';
import { PlayButton } from 'components/button/PlayButton';
import { Tagline } from 'components/tagline/Tagline';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { Globe } from 'components/globe';
import { CoverVideo } from 'components/video/CoverVideo';
import { Fade } from 'components/animations/Fade';

import s from './ImageBlock.module.scss';

type Props = {
  isSlice?: boolean;
  index?: number;
  tagline?: string;
  title?: string;
  text?: React.ReactNode;
  link?: ILink;
  label?: string;
  buttonText?: string;
  buttonCaption?: string;
  buttonSubline?: string;
  buttonNumber?: string;
  image?: IImage;
  direction?: string;
  globe?: string;
  video?: ILink;
  embed?: IEmbed;
  caption?: string;
  children?: React.ReactNode;
};

export const ImageBlock = ({
  tagline,
  title,
  text,
  index,
  image,
  video,
  embed,
  link,
  label,
  buttonText,
  buttonCaption,
  buttonSubline,
  buttonNumber,
  isSlice,
  direction,
  globe,
  caption,
  children,
}: Props) => {
  const [size, setSize] = React.useState(640);
  const reverse = direction && direction === 'Left';
  const showGlobe = globe && globe === 'Show globe';
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);
  const hasChildren = React.Children.count(children) > 0;

  const updateGlobeSize = (e) => {
    if (e.matches) {
      setSize(640);
    } else {
      setSize(480);
    }
  };

  React.useEffect(() => {
    const mqString = '(min-width: 1080px)';
    let mql = window.matchMedia(mqString);

    updateGlobeSize(mql);
    mql.addListener(updateGlobeSize);

    return () => {
      mql.removeListener(updateGlobeSize);
    };
  }, []);

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.block__layout}>
          <div className={s.block__contentCol}>
            {tagline && (
              <Fade isVisible={isVisible}>
                <Tagline index={index} text={tagline} />
              </Fade>
            )}
            {title && (
              <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                <h2 className={s.block__title}>{title}</h2>
              </Fade>
            )}
            {text && (
              <Fade isVisible={isVisible} delay={baseDelay}>
                <div className={s.block__row}>
                  <div className={s.block__text}>
                    <div className={s.block__copy}>{text}</div>
                  </div>
                </div>
              </Fade>
            )}
            {hasChildren && (
              <div className={s.block__row}>
                <div className={s.block__buttons}>
                  {React.Children.map(children, (c, i) => (
                    <Fade isVisible={isVisible} delay={baseDelay + 0.15 + 0.15 * i}>
                      <div className={s.block__button}>{c}</div>
                    </Fade>
                  ))}
                </div>
              </div>
            )}
            {link && (
              <Fade isVisible={isVisible} delay={baseDelay + (hasChildren ? 0.3 : 0.15)}>
                <div className={s.block__link}>
                  <MegaButton
                    label={buttonText}
                    caption={buttonCaption}
                    subline={buttonSubline}
                    number={buttonNumber}
                    link={link}
                    ariaLabel={buttonText || label}
                  >
                    {label || 'Read more'}
                  </MegaButton>
                </div>
              </Fade>
            )}
          </div>

          <div className={s.block__imageCol}>
            {image && !showGlobe && (
              <Fade isVisible={isVisible}>
                <Figure className={s.block__figure} caption={caption}>
                  <div className={s.block__image}>
                    {embed && (
                      <PlayButton
                        className={s.block__play}
                        embed={embed}
                        ariaLabel={`Play video ${title}`}
                      />
                    )}
                    {video && <CoverVideo className={s.block__video} video={video.href} />}
                    {image && <Image src={image.url} layout="fill" className={s.block__img} />}
                  </div>
                </Figure>
              </Fade>
            )}
            {showGlobe && (
              <Fade isVisible={isVisible}>
                <div className={s.block__globe}>
                  <div className={s.block__globeWrap}>
                    <Globe animate width={size} height={size} />
                  </div>
                </div>
              </Fade>
            )}
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection>
      <div className={s.block__segment}>
        <div className={s.block__container}>
          <div className={s.block__row}>
            <div className={s.block__col}>{content}</div>
          </div>
        </div>
      </div>
    </BackgroundSection>
  );

  return (
    <div className={classnames(s.block, { [s.reverse]: reverse, [s.globe]: showGlobe })}>
      {isSlice ? container : content}
    </div>
  );
};
