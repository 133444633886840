import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Image from 'next/image';

import { IImage, ILink } from 'utils/types';
import { IconButton } from 'components/button/IconButton';
import { Fade } from 'components/animations/Fade';

import s from './People.module.scss';

type Props = {
  name?: string;
  position?: string;
  bio?: React.ReactNode;
  tel?: string;
  email?: string;
  image?: IImage;
  altLink?: ILink;
  link?: ILink;
};

export const PeopleItem = ({ name, position, bio, tel, email, image, altLink, link }: Props) => {
  const mainLink = altLink?.href ? altLink : link;

  return (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.item}>
          <div className={s.item__wrap}>
            <Fade isVisible={isVisible}>
              {image && (
                <div className={s.item__image}>
                  <div className={s.item__aspect} />
                  <div className={s.item__imgWrap}>
                    <Image
                      src={image.portrait?.url ?? image.url}
                      layout="fill"
                      className={s.item__img}
                      alt={name}
                    />
                  </div>
                </div>
              )}
              <div className={s.item__content}>
                {name && <h3 className={s.item__name}>{name}</h3>}
                {position && <p className={s.item__position}>{position}</p>}
              </div>
              <div className={s.item__button}>
                <IconButton
                  color="white"
                  icon="plus"
                  {...mainLink}
                  aria-label={`More about ${name}`}
                />
              </div>
            </Fade>
          </div>
        </div>
      )}
    </VisibilitySensor>
  );
};
