import React from 'react';
import classnames from 'classnames';

import s from './Documents.module.scss';

type Props = {
  isSlice?: boolean;
  isSplitPage?: boolean;
  isArticle?: boolean;
  children: React.ReactNode;
};

export const Documents = ({
  isSlice = false,
  isSplitPage = false,
  isArticle = false,
  children,
}: Props) => {
  const classes = classnames(s.documents, {
    [s.split]: isSplitPage,
    [s.article]: isArticle,
    [s.full]: !isSplitPage && !isArticle,
  });

  const content = (
    <div className={s.documents__row}>
      <div className={s.documents__col}>
        <div className={s.documents__list}>{children}</div>
      </div>
    </div>
  );

  if (!isSlice) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <div className={classes}>
      <div className={s.documents__segment}>
        <div className={s.documents__container}>{content}</div>
      </div>
    </div>
  );
};
