import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import shuffle from 'lodash/shuffle';

import { Tagline } from 'components/tagline/Tagline';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { Fade } from 'components/animations/Fade';

import s from './Timeline.module.scss';

type Props = {
  index?: number;
  tagline?: React.ReactNode;
  text?: React.ReactNode;
  title?: string;
  isSlice?: boolean;
  children: React.ReactNode;
};

export const Timeline = ({ index, tagline = 'Year', title, text, isSlice, children }: Props) => {
  const timelineRef = React.useRef<HTMLDivElement | null>(null);
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);
  const childCount = React.Children.count(children);

  const childDelays = shuffle(new Array(childCount).map((_, x) => 0.03 * x));

  React.useEffect(() => {
    const item = timelineRef.current;
    item!.style.gridAutoRows = '10px';
  }, []);

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div>
          {title && (
            <div className={s.timeline__header}>
              {tagline && (
                <Fade isVisible={isVisible}>
                  <Tagline text={tagline} index={index} ordinal />
                </Fade>
              )}
              <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                <h2 className={s.timeline__title}>{title}</h2>
              </Fade>
              {text && (
                <Fade isVisible={isVisible} delay={baseDelay}>
                  <div className={s.timeline__text}>{text}</div>
                </Fade>
              )}
            </div>
          )}
          <div className={s.timeline__list} ref={timelineRef}>
            {React.Children.map(children, (component: JSX.Element, i) =>
              React.cloneElement(component, { isVisible, delay: baseDelay + childDelays[i] })
            )}
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection dark>
      <div className={s.timeline__segment}>
        <div className={s.timeline__container}>
          <div className={s.timeline__row}>
            <div className={s.timeline__col}>{content}</div>
          </div>
        </div>
      </div>
    </BackgroundSection>
  );

  return <div className={s.timeline}>{isSlice ? container : content}</div>;
};
