import React from 'react';
import classnames from 'classnames';

import { ILink } from 'utils/types';
import { Tagline } from 'components/tagline/Tagline';
import { Link } from 'components/link/Link';
import { IconDownload } from 'components/assets';

import s from './MediaList.module.scss';

type Props = {
  title?: string;
  aspect?: string | 'Portrait' | 'Landscape';
  archive?: ILink;
  isSlice?: boolean;
  children: React.ReactNode;
};

export const MediaList = ({ title, aspect, archive, isSlice, children }: Props) => {
  const childCount = React.Children.count(children);

  const classes = classnames(s.medialist, {
    [s.even]: childCount % 2 == 0,
    [s.two]: childCount === 2,
    [s.three]: childCount === 3,
    [s.four]: childCount === 4,
    [s.many]: childCount > 4,
    [s.portrait]: aspect === 'Portrait',
  });

  const content = (
    <>
      <div className={s.medialist__header}>
        {title && (
          <div className={s.medialist__headline}>
            <Tagline size="long" />
            <h2 className={s.medialist__title}>{title}</h2>
          </div>
        )}
        {archive && (
          <Link
            className={s.medialist__download}
            download
            aria-label={`Download ${title} media zip file`}
            href={archive.href}
            // {...archive}
          >
            <IconDownload /> Download zip
          </Link>
        )}
      </div>
      <div className={s.medialist__list}>{children}</div>
    </>
  );

  if (!isSlice) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <div className={classes}>
      <div className={s.medialist__segment}>
        <div className={s.medialist__container}>{content}</div>
      </div>
    </div>
  );
};
