import React, { ChangeEvent, FocusEvent } from 'react';
import classnames from 'classnames';

import s from './Select.module.scss';

type Props = {
  id: string;
  name: string;
  label: string;
  placeholder?: string;
  type?: 'text' | 'email' | 'textarea';
  disabled?: boolean;
  options: string[];
  required?: boolean;
  error?: any;
  touched?: any;
  onChange?: (e: ChangeEvent<any>) => void;
  onBlur?: (e: FocusEvent<any>) => void;
};

export const Select = ({
  id,
  name,
  label,
  placeholder,
  type,
  disabled,
  options,
  required,
  error,
  touched,
  onChange = () => {},
  onBlur,
  ...rest
}: Props) => {
  const [state, setState] = React.useState({
    value: !placeholder ? options[0] : placeholder,
  });

  function handleChange(e) {
    let { value } = e.target;
    setState({
      value: value,
    });
    onChange(e);
  }

  let className = classnames(s.select__input, {
    [s.disabled]: state.value === placeholder,
  });

  return (
    <div className={s.select}>
      <label htmlFor={id} className={s.select__label}>
        <span>{label}</span>
      </label>
      <div className={s.select__dropdown}>
        <select
          id={id}
          name={name}
          className={className}
          disabled={disabled}
          onChange={handleChange}
          onBlur={onBlur}
          value={state.value}
          // required={required}
          aria-required={required}
          aria-invalid={error && touched ? true : false}
          {...rest}
        >
          {placeholder && <option disabled>{placeholder}</option>}
          {options.map((option, index) => (
            <option key={index} /* value={option.replace(/\s+/g, '-').toLowerCase()}*/>
              {option}
            </option>
          ))}
        </select>
      </div>
      {error && touched && (
        <p className={s.select__message} id={`${id}_error`} role="alert">
          {error}
        </p>
      )}
    </div>
  );
};
