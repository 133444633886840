import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import classnames from 'classnames';
import shuffle from 'lodash/shuffle';

import { Tagline } from 'components/tagline/Tagline';
import { Fade } from 'components/animations/Fade';

import s from './GalleryGrid.module.scss';

type Props = {
  isSlice?: boolean;
  isSplitPage?: boolean;
  isArticle?: boolean;
  tagline?: string;
  title?: string;
  children: React.ReactNode;
};

export const GalleryGrid = ({
  tagline,
  title,
  isSlice,
  isSplitPage,
  isArticle,
  children,
}: Props) => {
  const childCount = React.Children.count(children);
  const childDelays = shuffle(new Array(childCount).map((_, x) => 0.03 * x));
  const classes = classnames(s.grid, {
    [s.split]: isSplitPage,
    [s.article]: isArticle,
    [s.full]: !isSplitPage && !isArticle,
    [s.large]: childCount > 8,
  });

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div>
          <div className={s.grid__header}>
            {tagline && (
              <Fade isVisible={isVisible}>
                <Tagline text={tagline} />
              </Fade>
            )}
            {title && (
              <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                <h2 className={s.grid__title}>{title}</h2>
              </Fade>
            )}
          </div>
          <div className={s.grid__row}>
            <div className={s.grid__column}>
              <div className={s.grid__layout}>
                {React.Children.map(children, (component: JSX.Element, i) =>
                  React.cloneElement(component, { isVisible, delay: childDelays[i] })
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  if (!isSlice) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <div className={classes}>
      <div className={s.grid__segment}>
        <div className={s.grid__container}>{content}</div>
      </div>
    </div>
  );
};
