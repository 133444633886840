import React from 'react';
import cn from 'classnames';

import s from './Pipeline.module.scss';

type Props = {
  title: string;
  biosimilarCandidate: string;
  referenceBiologic: string;
  therapeuticArea: string;
  approvals?: Array<{
    name: string;
  }>;
  currentStage: string;
  steps?: string[];
};

export const PipelineItem = ({
  title,
  biosimilarCandidate,
  referenceBiologic,
  therapeuticArea,
  approvals,
  currentStage,
  steps,
}: Props) => {
  const stages = steps || [
    'Early Phase',
    'Pre-clinical',
    'Clinical Trials',
    'Filing',
    'Approval',
    'Launch',
  ];

  const checkStep = (step: string) => {
    switch (step) {
      case 'pre-Registration':
        return 'Preclinical';
      case 'Phase I':
        return 'Phase 1';
      case 'Phase II':
        return 'Phase 2';
      case 'Phase III':
        return 'Phase 3 Pivotal BA/BE';
      case 'NDA Filed':
        return 'NDA Submission';
      default:
        return step;
    }
  };

  return (
    <tr>
      <th>{title}</th>
      <td>{therapeuticArea}</td>
      {/* <td
        colSpan={6}
        // key={index}
        className={s.pipelineItem__cell}
        aria-label={checkStep(currentStage)}
      >
        <div className={s.pipelineItem__progress}>
          <div
            className={s.pipelineItem__fill}
            style={{
              // width based on current step
              width: `${((stages.indexOf(checkStep(currentStage)) + 1) / stages.length) * 100}%`,
            }}
          />
        </div>
      </td> */}
      {stages.map((stage, index) => {
        return (
          <td
            key={index}
            className={cn(s.pipelineItem__cell)}
            aria-label={checkStep(currentStage) === stage ? 'true' : 'false'}
          >
            <div
              className={cn(s.pipelineItem__progress, {
                [s.first]: index === 0,
                [s.last]: index === stages.length - 1,
              })}
            >
              <div className={s.pipelineItem__fill} />
            </div>
          </td>
        );
      })}
    </tr>
  );
};
