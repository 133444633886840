import React from 'react';
import Image from 'next/image';
import VisibilitySensor from 'react-visibility-sensor';

import { IImage } from 'utils/types';
import { Fade } from 'components/animations/Fade';

import s from './Infographic.module.scss';

type Props = {
  number?: string;
  image?: IImage;
  text?: React.ReactNode;
  isSlice?: boolean;
  isSplitPage?: boolean;
  delay?: number;
  parentVisible?: boolean;
};

export const Infographic = ({
  number,
  image,
  text,
  isSlice,
  isSplitPage,
  delay,
  parentVisible,
}: Props) => {
  const hasNumber = number && number.length > 0;
  const hasImage = image;

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <Fade isVisible={parentVisible || isVisible} delay={delay}>
          <figure className={s.infographic__figure}>
            <div className={s.infographic__content}>
              {image && (
                <div className={s.infographic__image}>
                  <Image
                    src={image.url}
                    width={image.width}
                    height={image.height}
                    // layout="fill"
                    // layout="responsive"
                    // objectFit="cover"
                    // className={s.infographic__img}
                  />
                </div>
              )}
              {hasNumber && !hasImage && (
                <blockquote className={s.infographic__number}>{number}</blockquote>
              )}
              {text && <figcaption className={s.infographic__caption}>{text}</figcaption>}
            </div>
          </figure>
        </Fade>
      )}
    </VisibilitySensor>
  );

  if (isSlice && isSplitPage) {
    return (
      <div className={s.infographic}>
        <div className={s.infographic__splitSegment}>{content}</div>
      </div>
    );
  }

  return <div className={s.infographic}>{content}</div>;
};
