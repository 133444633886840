import React from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

import { IEmbed, ILink } from 'utils/types';
import { Image, Embed } from 'components/prismic';
import { Link } from 'components/link/Link';
import { IconMute } from 'components/assets';

import s from './Media.module.scss';

type Props = {
  data: IPrismicEmbed | IPrismicMedia | IEmbed;
  link?: ILink;
  caption?: string | JSX.Element;
  isVisible?: Boolean;
  placeholder?: string;
  preferedVersion?: string[];
  alt?: string;
};

export const Media = ({
  data,
  link,
  caption,
  isVisible = true,
  preferedVersion,
  alt,
  ...rest
}: Props) => {
  const [volume, setVolume] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const playVideo = isVisible ? true : false;
  const muted = volume > 0 ? false : true;
  const hasCaption =
    caption &&
    ((typeof caption === 'string' && caption.length > 0) ||
      (typeof caption !== 'string' && React.Children.count(caption) > 0));

  React.useEffect(() => {
    isVisible === false && setVolume(0);
  }, [isVisible]);

  const unmute = () => {
    setVolume(0.5);
  };

  const videoEl = (
    <div className={s.media__aspect}>
      <div className={classnames(s.media__overlay, { [s.disabled]: !muted })} onClick={unmute}>
        <IconMute />
      </div>
      <div className={classnames(s.media__ready, { [s.visible]: visible })}>
        <ReactPlayer
          url={(data as IEmbed).embedUrl}
          width="100%"
          height="100%"
          playsinline
          playing={playVideo}
          muted={muted}
          volume={volume}
          controls={true}
          onStart={() => setVisible(true)}
          // config={{
          //   vimeo: {
          //     preload: true,
          //   },
          // }}
        />
      </div>
    </div>
  );

  const embedEl = (
    <div className={s.media__aspect}>
      <Embed data={data as IPrismicEmbed} {...rest} />
    </div>
  );

  const imageEl = <Image className={s.media__image} data={data as any} alt={alt} />;

  return (
    <figure className={s.media}>
      {data.type === 'image' &&
        (link ? (
          <Link className={s.media__link} link={link}>
            {imageEl}
          </Link>
        ) : (
          imageEl
        ))}
      {data.type === 'embed' && embedEl}
      {data.type === 'video' && videoEl}
      {hasCaption && (
        <figcaption className={s.media__caption}>
          {typeof caption === 'string' ? <p>{caption}</p> : caption}
        </figcaption>
      )}
    </figure>
  );
};
