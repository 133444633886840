import React from 'react';
import { Tagline } from 'components/tagline/Tagline';
import { Fade } from 'components/animations/Fade';

import s from './ItemList.module.scss';

type Props = {
  index?: number;
  title?: React.ReactNode;
  text?: React.ReactNode;
  isVisible?: boolean;
  delay?: number;
  numbered?: boolean;
};

export const ItemListItem = ({
  index,
  title,
  text,
  isVisible,
  delay = 0,
  numbered = true,
}: Props) => {
  return (
    <div className={s.item}>
      <Fade isVisible={isVisible} delay={delay}>
        <div className={s.item__content}>
          {index && <Tagline index={numbered ? index : undefined} />}
          <div className={s.item__content}>
            {title && <h3 className={s.item__title}>{title}</h3>}
            {text && <div className={s.item__text}>{text}</div>}
          </div>
        </div>
      </Fade>
    </div>
  );
};
