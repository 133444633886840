import React from 'react';
import Image from 'next/image';

import { IImage } from 'utils/types';
import { Fade } from 'components/animations/Fade';

import s from './Timeline.module.scss';

type Props = {
  title?: string;
  category?: string;
  text?: React.ReactNode;
  image?: IImage;
  isVisible?: boolean;
  delay?: number;
  caption?: React.ReactNode;
};

export const TimelineItem = ({
  title,
  category,
  text,
  image,
  isVisible,
  delay = 0,
  caption,
}: Props) => {
  const itemRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    gridCalc();
    window.addEventListener('resize', gridCalc);

    return () => {
      window.removeEventListener('resize', gridCalc);
    };
  }, []);

  const gridCalc = () => {
    const item = itemRef.current;
    const firstChild = item!.firstChild as HTMLDivElement;

    item!.style.gridRow = `span ${Math.ceil(firstChild.offsetHeight / 10)}`;
  };

  return (
    <article className={s.item} ref={itemRef}>
      <div className={s.item__wrap}>
        <Fade isVisible={isVisible} delay={delay}>
          <div className={s.item__box}>
            <div className={s.item__description}>
              {image && (
                <div className={s.item__image}>
                  <Image
                    src={image.url}
                    width={image.width}
                    height={image.height}
                    layout="responsive"
                    className={s.item__img}
                    onLoad={gridCalc}
                  />
                  <div className={s.item__aspec} />
                </div>
              )}
              {category && <p className={s.item__category}>{category}</p>}
              {title && <h3 className={s.item__title}>{title}</h3>}
              {text && <div className={s.item__text}>{text}</div>}
            </div>
          </div>
        </Fade>
      </div>
    </article>
  );
};
