import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';

import { BackgroundSection } from 'components/background/BackgroundSection';
import { IEmbed, IImage, ILink } from 'utils/types';
import { Tagline } from 'components/tagline/Tagline';
import { MegaButton } from 'components/button/MegaButton';
import { ArrowButton } from 'components/button/ArrowButton';
import { PlayButton } from 'components/button/PlayButton';
import { CoverVideo } from 'components/video/CoverVideo';

import s from './Hero.module.scss';

type Props = {
  index?: number;
  tagline?: React.ReactNode;
  title: React.ReactNode;
  text?: React.ReactNode;
  image?: IImage;
  link?: ILink;
  linkText?: string;
  video?: ILink;
  embed?: IEmbed;
};

export const Hero = ({
  index,
  tagline,
  title,
  text,
  image,
  video,
  embed,
  link,
  linkText,
}: Props) => {
  const hasText = text && React.Children.count(text) > 0;

  const navOffset = true; //home.navigationSlices.length > 0; // TODO: need to pass in if there is a navigation

  return (
    <BackgroundSection>
      <div className={classnames(s.hero, { [s.full]: !!image, [s.hasNav]: navOffset })}>
        <div className={s.hero__container}>
          <div className={s.hero__content}>
            <div className={s.hero__row}>
              <div className={s.hero__column}>
                {tagline && <Tagline index={index} text={tagline} />}
                <h1 className={s.hero__title}>{title}</h1>
              </div>
            </div>

            {hasText && (
              <div className={s.hero__row}>
                <div className={s.hero__text}>{text}</div>
              </div>
            )}

            {link && linkText && (
              <div className={s.hero__row}>
                <div className={s.hero__button}>
                  <MegaButton link={link} ariaLabel={linkText}>
                    {linkText}
                  </MegaButton>
                </div>
              </div>
            )}
          </div>
        </div>

        {(image || video) && (
          <div className={s.hero__background}>
            {image && (
              <Image
                src={image.portrait?.url || image.url}
                layout="fill"
                className={s.hero__image}
                alt=""
                priority
              />
            )}

            {video && <CoverVideo className={s.hero__video} video={video.href} />}
            {embed && <PlayButton className={s.hero__play} embed={embed} />}
            <ArrowButton
              className={s.hero__arrow}
              tabIndex={-1}
              direction="down"
              color="white"
              scrollOnClick
            />
          </div>
        )}
      </div>
    </BackgroundSection>
  );
};
