import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import classnames from 'classnames';
import shuffle from 'lodash/shuffle';

import { BackgroundSection } from 'components/background/BackgroundSection';
import { Fade } from 'components/animations/Fade';

import s from './ItemList.module.scss';

type Props = {
  title?: string;
  text?: React.ReactNode;
  isSlice?: boolean;
  isSplitPage?: boolean;
  children: React.ReactNode;
};

export const ItemList = ({ title, text, isSlice, isSplitPage, children }: Props) => {
  const baseDelay = title ? 0.15 : 0;
  const childCount = React.Children.count(children);

  const childDelays = shuffle(new Array(childCount).map((_, x) => 0.03 * x));

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div>
          {title && (
            <div className={s.list__header}>
              <Fade isVisible={isVisible}>
                <h2 className={s.list__title}>{title}</h2>
              </Fade>
              {text && (
                <Fade isVisible={isVisible} delay={baseDelay}>
                  <div className={s.list__text}>{text}</div>
                </Fade>
              )}
            </div>
          )}
          <div className={s.list__list}>
            {React.Children.map(children, (component: JSX.Element, i) =>
              React.cloneElement(component, { isVisible, delay: baseDelay + childDelays[i] })
            )}
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection>
      <div className={s.list__segment}>
        <div className={s.list__container}>
          <div className={s.list__row}>
            <div className={s.list__col}>{content}</div>
          </div>
        </div>
      </div>
    </BackgroundSection>
  );

  if (!isSlice) {
    return <div className={s.list}>{content}</div>;
  }

  if (isSplitPage) {
    return (
      <div className={classnames(s.list, s.split)}>
        <div className={s.list__splitSegment}>{content}</div>
      </div>
    );
  }

  return <div className={classnames(s.list, s.full)}>{container}</div>;
};
