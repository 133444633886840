import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';

import { IImage } from 'utils/types';
import { Caption } from 'components/media/Caption';

import s from './Gallery.module.scss';

type Props = {
  title?: string;
  text?: string;
  image?: IImage;
  selected?: boolean;
  size?: 'main' | 'square' | string;
  caption?: React.ReactNode;
  index?: number;
  total?: number;
};

export const GalleryItem = ({ image, selected, size = 'main', caption, index, total }: Props) => {
  const hasCaption =
    caption &&
    ((typeof caption === 'string' && caption.length > 0) ||
      (typeof caption !== 'string' && React.Children.count(caption) > 0));

  return (
    <li
      className={classnames(s.item, { [s.selected]: selected })}
      aria-roledescription="slide"
      aria-label={`${index} of ${total}`}
    >
      {image && (
        <>
          <Image src={image[size]?.url ?? image.url} layout="fill" className={s.item__img} />
          {hasCaption && <Caption className={s.item__caption} caption={caption} />}
        </>
      )}
    </li>
  );
};
