import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import shuffle from 'lodash/shuffle';

import { Tagline } from 'components/tagline/Tagline';
import { Fade } from 'components/animations/Fade';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { IconSearch } from 'components/assets';
import { Select } from 'components/form/Select';

import s from './Catalog.module.scss';

export type FilterTypes = 'All' | 'Generics' | 'Injectables' | string;

type Props = {
  title?: React.ReactNode;
  tagline?: React.ReactNode;
  isSlice?: boolean;
  option?: FilterTypes;
  symbols?: string[];
  term?: string;
  filters?: string[];
  alphabetFilter?: boolean;
  searchFilter?: boolean;
  lang?: string;
  onOptionChange?: (option: FilterTypes) => void;
  onSymbolToggled?: (char: string) => void;
  onTermChanged?: (q: string) => void;
  children: React.ReactNode;
};

export const Catalog = ({
  title,
  tagline,
  isSlice,
  option = 'All',
  symbols = [],
  term,
  alphabetFilter = true,
  searchFilter = true,
  lang,
  onOptionChange = () => {},
  onSymbolToggled = () => {},
  onTermChanged = () => {},
  filters = ['All', 'Generics', 'Injectables'],
  children,
}: Props) => {
  const searchEl = React.useRef<HTMLInputElement>(null);
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
  const hangul = 'ㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ'.split('');
  const alphabets = lang === 'ko-kr' ? hangul : alphabet;
  const characterDelays = shuffle(
    [...new Array(alphabets.length).fill(null)].map((_, x) => 0.01 * x)
  );
  const options: FilterTypes[] = filters;

  const [searchOpen, setSearchOpen] = React.useState(false);

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const openSearch = () => {
    setSearchOpen(true);
  };

  const onBlur = () => {
    const searchValue = searchEl.current && searchEl.current.value;
    if (searchValue === '') {
      setSearchOpen(false);
    }
  };

  React.useEffect(() => {
    if (searchOpen) {
      searchEl.current && searchEl.current.focus();
    }

    return () => {};
  }, [searchOpen]);

  React.useEffect(() => {
    searchEl.current && searchEl.current.addEventListener('blur', onBlur);

    return () => {
      searchEl.current && searchEl.current.removeEventListener('blur', onBlur);
    };
  }, []);

  const optionsList = (
    <ul className={classnames(s.catalog__optionsList, { [s.visible]: !searchOpen })}>
      {options.map((str) => (
        <li className={s.catalog__optionsItem} key={str}>
          <button
            className={classnames(s.catalog__optionsButton, {
              [s.active]: str === option,
            })}
            onClick={() => {
              onOptionChange(str);
            }}
          >
            {str}
          </button>
        </li>
      ))}
    </ul>
  );

  const optionsSelect = (
    <div className={s.dropdown}>
      <select
        className={s.dropdown__input}
        id="indexFilter"
        name="indexFilter"
        onChange={(e) => onOptionChange(e.target.value)}
      >
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div>
          <div className={s.catalog__header}>
            <div className={s.catalog__text}>
              {tagline && (
                <Fade isVisible={isVisible}>
                  <Tagline text={tagline} />
                </Fade>
              )}
              {title && (
                <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                  <h1 className={s.catalog__title}>{title}</h1>
                </Fade>
              )}
            </div>
            <div className={s.catalog__opFade}>
              <Fade isVisible={isVisible} delay={baseDelay}>
                <div className={s.catalog__options}>
                  {options.length > 3 ? optionsSelect : optionsList}
                  {searchFilter && (
                    <div className={classnames(s.catalog__search, { [s.open]: searchOpen })}>
                      <button
                        className={s.catalog__searchButton}
                        onClick={openSearch}
                        tabIndex={-1}
                        aria-label="Toggle search"
                      >
                        <IconSearch />
                      </button>
                      <div className={s.catalog__searchWrap}>
                        <input
                          className={s.catalog__searchInput}
                          type="text"
                          placeholder="Search"
                          ref={searchEl}
                          defaultValue={term}
                          onChange={(e) => {
                            onTermChanged(e.target.value);
                          }}
                          onFocus={openSearch}
                          aria-label="Search"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Fade>
            </div>
          </div>
          {alphabetFilter && (
            <Fade isVisible={isVisible} delay={baseDelay}>
              <div className={classnames(s.catalog__filter, { [s.korean]: lang === 'ko-kr' })}>
                <ul className={s.catalog__filterList}>
                  {alphabets.map((char, i) => (
                    <li className={s.catalog__filterItem} key={i}>
                      <Fade isVisible={isVisible} delay={baseDelay + 0.17 + characterDelays[i]}>
                        <button
                          className={classnames(s.catalog__filterButton, {
                            [s.active]: symbols.includes(char),
                          })}
                          onClick={() => onSymbolToggled(char)}
                          aria-label={`Filter products starting with the letter "${char}"`}
                        >
                          {char}
                        </button>
                      </Fade>
                    </li>
                  ))}
                </ul>
              </div>
            </Fade>
          )}
          <Fade isVisible={isVisible} delay={baseDelay}>
            {children}
          </Fade>
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection>
      <div className={s.catalog__segment}>
        <div className={s.catalog__container}>
          <div className={s.catalog__row}>
            <div className={s.catalog__col}>{content}</div>
          </div>
        </div>
      </div>
    </BackgroundSection>
  );

  return <div className={s.catalog}>{isSlice ? container : content}</div>;
};
