import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import { IImage } from 'utils/types';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { SliceHero } from 'components/hero/SliceHero';

import s from './ColumnSection.module.scss';

type Props = {
  isSlice?: boolean;
  title?: string;
  tagline?: string;
  image?: IImage;
  numbered?: boolean;
  children: React.ReactNode;
  color?: 'brand' | 'white' | 'dark' | string;
};

export const ColumnSection = ({
  isSlice,
  title,
  tagline,
  image,
  numbered,
  children,
  color = 'white',
}: Props) => {
  // const baseDelay = title ? 0.15 : 0;
  const childCount = React.Children.count(children);
  const hasImage = !!image;
  const classes = classnames(s.columns__list, {
    [s.three]: childCount > 2,
    [s.numbered]: numbered,
  });

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div>
          <div className={classes}>
            {React.Children.map(children, (component: JSX.Element, i) =>
              // return max 3 children
              i < 3
                ? React.cloneElement(component, { isVisible, delay: i * 0.15, numbered, color })
                : null
            )}
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection>
      <section className={s.columns__segment}>
        <div className={s.columns__wrap}>
          <div className={s.columns__container}>
            <SliceHero
              title={title}
              tagline={tagline}
              image={image}
              underline={!numbered}
              color={color}
            />
          </div>
          <div className={s.columns__background}>
            <div className={s.columns__container}>{content}</div>
          </div>
        </div>
      </section>
    </BackgroundSection>
  );

  return (
    <div
      className={classnames(s.columns, s[color], {
        [s.underline]: !numbered && (!tagline || hasImage),
        [s.hasImage]: hasImage,
      })}
    >
      {isSlice ? container : content}
    </div>
  );
};
