import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { Tagline } from 'components/tagline/Tagline';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { Fade } from 'components/animations/Fade';

import s from './People.module.scss';

type Props = {
  tagline?: string;
  title: string;
  text?: React.ReactNode;
  isSlice?: boolean;
  children: React.ReactNode;
};

export const People = ({ tagline, title, text, isSlice, children }: Props) => {
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.people__list}>
          <div className={s.people__header}>
            {tagline && (
              <Fade isVisible={isVisible}>
                <Tagline text={tagline} size="long" />
              </Fade>
            )}
            <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
              <h2 className={s.people__title}>{title}</h2>
            </Fade>
            <div className={s.people__row}>
              {text && (
                <Fade isVisible={isVisible} delay={baseDelay}>
                  <div className={s.people__text}>{text}</div>
                </Fade>
              )}
            </div>
          </div>
          {children}
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection>
      <div className={s.people__segment}>
        <div className={s.people__container}>
          <div className={s.people__row}>
            <div className={s.people__col}>{content}</div>
          </div>
        </div>
      </div>
    </BackgroundSection>
  );

  return <div className={s.people}>{isSlice ? container : content}</div>;
};
