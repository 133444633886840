import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import classnames from 'classnames';

import { ILink } from 'utils/types';
import { MegaButton } from 'components/button/MegaButton';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { Fade } from 'components/animations/Fade';
import { IconText } from 'components/assets';

import s from './TextBlock.module.scss';

type Props = {
  isSlice?: boolean;
  index?: number;
  tagline?: string;
  title?: string;
  text?: JSX.Element | JSX.Element[];
  textLength?: number;
  link?: ILink;
  label?: string;
  buttonText?: string;
  buttonCaption?: string;
  buttonSubline?: string;
  buttonNumber?: string;
  children?: React.ReactNode;
  color?: 'brand' | 'white' | 'dark' | 'none' | string;
};

export const TextBlock = ({
  tagline,
  title,
  text,
  textLength = 0,
  index,
  link,
  label,
  buttonText,
  buttonCaption,
  buttonSubline,
  buttonNumber,
  isSlice,
  children,
  color = 'none',
}: Props) => {
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);
  const hasChildren = React.Children.count(children) > 0;
  const hasColumns = textLength > 400;

  // console.log(textLength);

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.block__layout}>
          <div className={s.block__box}>
            {/* {tagline && (
              <Fade isVisible={isVisible}>
                <Tagline index={index} text={tagline} />
              </Fade>
            )} */}
            <div className={s.block__header}>
              {title && (
                <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                  <h2 className={s.block__title}>
                    <IconText /> {title}
                  </h2>
                </Fade>
              )}
            </div>
            {text && (
              <div className={s.block__text}>
                <Fade isVisible={isVisible} delay={baseDelay}>
                  <div className={s.block__copy}>{text}</div>
                </Fade>
              </div>
            )}
            {link?.href && (
              <Fade isVisible={isVisible} delay={baseDelay + 0.15}>
                <div className={s.block__link}>
                  <MegaButton
                    label={buttonText}
                    caption={buttonCaption}
                    subline={buttonSubline}
                    number={buttonNumber}
                    link={link}
                    ariaLabel={buttonText || label}
                  >
                    {label || 'Read more'}
                  </MegaButton>
                </div>
              </Fade>
            )}
          </div>
          {hasChildren && <div className={s.block__aside}>{children}</div>}
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection>
      <div className={s.block__segment}>
        <div className={s.block__container}>{content}</div>
      </div>
    </BackgroundSection>
  );

  return (
    <div
      className={classnames(s.block, s[color], { [s.full]: !hasChildren, [s.twocol]: hasColumns })}
    >
      {isSlice ? container : content}
    </div>
  );
};
