import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import { Tagline } from 'components/tagline/Tagline';
import { Fade } from 'components/animations/Fade';

import s from './VideoList.module.scss';

type Props = {
  tagline?: string;
  title?: string;
  text?: React.ReactNode;
  isSlice?: boolean;
  children: React.ReactNode;
};

export const VideoList = ({ tagline, title, text, isSlice, children }: Props) => {
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);
  const hasTitle = title && title.length > 0;
  const hasTagline = tagline && tagline.length > 0;
  const hasListItems = React.Children.toArray(children).length > 1;

  const classes = classnames(s.videos, { [s.hasList]: hasListItems });

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.videos__layout}>
          {hasTitle && (
            <div className={s.videos__header}>
              {hasTagline && (
                <Fade isVisible={isVisible}>
                  <Tagline text={tagline} />
                </Fade>
              )}
              <Fade isVisible={isVisible} delay={0.15}>
                <h2 className={s.videos__title}>{title}</h2>
              </Fade>
              {text && (
                <Fade isVisible={isVisible} delay={baseDelay}>
                  <div className={s.videos__text}>{text}</div>
                </Fade>
              )}
            </div>
          )}
          <div className={s.videos__primary}>
            {React.Children.map(children, (component: JSX.Element, i) =>
              // return first child
              i < 1 ? React.cloneElement(component) : null
            )}
          </div>
          {hasListItems && (
            <div className={s.videos__list}>
              {React.Children.map(children, (component: JSX.Element, i) =>
                // return rest of video
                i > 0 ? React.cloneElement(component) : null
              )}
            </div>
          )}
        </div>
      )}
    </VisibilitySensor>
  );

  if (!isSlice) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <div className={classes}>
      <div className={s.videos__segment}>
        <div className={s.videos__container}>{content}</div>
      </div>
    </div>
  );
};
