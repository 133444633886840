import React from 'react';
import classnames from 'classnames';

import { Tagline } from 'components/tagline/Tagline';

import s from './Form.module.scss';

type Props = {
  tagline?: string;
  title?: string;
  text?: React.ReactNode;
  children: React.ReactNode;
  isSlice?: boolean;
  isSplitPage?: boolean;
};

export const Form = ({ tagline, title, text, children, isSlice, isSplitPage }: Props) => {
  const classes = classnames(s.form, {
    [s.split]: isSplitPage,
    [s.full]: !isSplitPage,
  });

  const content = (
    <div className={s.form__row}>
      <div className={s.form__layout}>
        {(title || text) && (
          <div className={s.form__header}>
            {title && <Tagline text={tagline} />}
            <h1 className={s.form__title}>{title}</h1>
            {text && <div className={s.form__copy}>{text}</div>}
          </div>
        )}
        <div className={s.form__fields}>{children}</div>
      </div>
    </div>
  );

  if (!isSlice) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <div className={classes}>
      <div className={s.form__segment}>
        <div className={s.form__container}>
          <div className={s.form__row}>
            <div className={s.form__col}>{content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
