import React from 'react';
import classnames from 'classnames';

import { ILink } from 'utils/types';
import { IconDownload } from 'components/assets';

import s from './Documents.module.scss';

type Props = {
  title?: string;
  link?: ILink;
  filename?: string;
  filetype?: string;
  filesize?: number;
  single?: boolean;
};

export const DocumentsItem = ({ title, link, filename, filetype, filesize, single }: Props) => {
  // const formatFileSize = () => {
  //   if (!filesize) return;

  //   let bytes = parseInt(filesize);

  //   if (bytes == 0) return '0 Bytes';
  //   var k = 1000,
  //     dm = 2, // decimal point
  //     sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  //     i = Math.floor(Math.log(bytes) / Math.log(k));

  //   if (filesize.length < 7) {
  //     return Math.round(bytes / Math.pow(k, i)).toString() + ' ' + sizes[i];
  //   } else {
  //     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  //   }
  // };

  return (
    <div className={classnames(s.item, { [s.single]: single === true })}>
      {link && (
        <>
          <a
            className={s.item__link}
            href={link.href}
            target="_blank"
            aria-label={`Open ${title || filename}.${filetype}`}
            download
          >
            <span className={s.item__icon}>
              <IconDownload />
            </span>
            <span className={s.item__name}>{title || filename}</span>
          </a>
          {/* {(filetype || filesize) && (
            <div className={s.item__info}>
              {filetype && <div className={s.item__filetype}>{filetype}</div>}
              {filesize && <div className={s.item__filesize}>{formatFileSize()}</div>}
            </div>
          )} */}
        </>
      )}
    </div>
  );
};
