import React from 'react';
import classnames from 'classnames';
import { Link } from 'components/link/Link';

import { ChevronRight } from 'components/assets';

import s from './TextButton.module.scss';

type Props = {
  color?: 'brand' | 'white' | 'dark' | string;
  staticColor?: boolean;
  iconColor?: 'dark';
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  [rest: string]: any;
};

export const TextButton = ({
  color = 'white',
  iconColor,
  href,
  staticColor,
  children,
  ...rest
}: Props) => {
  const className = classnames(s.button, {
    [s.brand]: color === 'brand',
    [s.white]: color === 'white',
    [s.dark]: color === 'dark',
    [s.static]: staticColor,
    [s.iconDark]: iconColor === 'dark',
  });
  const content = (
    <span className={s.button__content}>
      <span className={s.button__icon}>
        <ChevronRight />
      </span>
      <span className={s.button__text}>{children}</span>
    </span>
  );

  if (href) {
    return (
      <Link className={className} href={href} {...rest} target={!!rest.size ? '_blank' : '_self'}>
        {content}
      </Link>
    );
  }

  return (
    <button className={className} {...rest}>
      {content}
    </button>
  );
};
