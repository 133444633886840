import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { ILink } from 'utils/types';
import { IconButton } from 'components/button/IconButton';
import { Tagline } from 'components/tagline/Tagline';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { Globe } from 'components/globe';
import { Fade } from 'components/animations/Fade';

import s from './CTA.module.scss';

type Props = {
  index?: number;
  tagline?: string;
  title: string;
  text: React.ReactNode;
  label?: string;
  link?: ILink;
  globe?: boolean;
  graphic?: string;
  isSlice?: boolean;
};

export const CTA = ({
  index,
  tagline,
  title,
  text,
  label,
  link,
  globe,
  graphic,
  isSlice,
}: Props) => {
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);

  // console.log(graphic);

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.cta__row}>
          <div className={s.cta__content}>
            {tagline && (
              <Fade isVisible={isVisible}>
                <Tagline index={index} text={tagline} />
              </Fade>
            )}

            <div className={s.cta__header}>
              <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                <h2 className={s.cta__title}>{title}</h2>
              </Fade>
            </div>

            <Fade isVisible={isVisible} delay={baseDelay}>
              <div className={s.cta__text}>{text}</div>
            </Fade>

            {(label || link) && (
              <Fade isVisible={isVisible} delay={baseDelay + 0.15}>
                <div className={s.cta__button}>
                  <IconButton {...link} aria-label={label || 'Read more'}>
                    {label || 'Read more'}
                  </IconButton>
                </div>
              </Fade>
            )}
          </div>
          <div className={s.cta__figure}>
            {!globe && <div className={s.cta__image} />}
            {globe && <Globe animate />}
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <BackgroundSection color="dark">
      <div className={s.cta__segment}>
        <div className={s.cta__container}>{content}</div>
      </div>
    </BackgroundSection>
  );

  return <div className={s.cta}>{isSlice ? container : content}</div>;
};
