import React from 'react';

import { setQueryParameters } from 'utils/setQueryParameters';
import { IImage } from 'utils/types';

import s from './Catalog.module.scss';

type Props = {
  image?: IImage;
  ndc?: string;
  strength?: string;
  size?: string;
  cgn?: string;
  seq?: string;
};

export const Variant = ({ image, ndc, strength, size, cgn, seq }: Props) => {
  return (
    <tr className={s.variant}>
      <td className={s.variant__cell}>
        {image && (
          <div className={s.variant__image}>
            <picture>
              <source srcSet={setQueryParameters(image.url, 'fit=max&w=90&h=60')} />
              <img src={image.url} className={s.variant__img} alt="" />
            </picture>
          </div>
        )}
      </td>
      <td className={s.variant__cell} data-label="NDC#">
        {ndc}
      </td>
      <td className={s.variant__cell} data-label="Strength">
        {strength}
      </td>
      <td className={s.variant__cell} data-label="PKG size">
        {size}
      </td>
      <td className={s.variant__cell} data-label="CGN">
        {cgn}
      </td>
      <td className={s.variant__cell} data-label="CGN SEQ#">
        {seq}
      </td>
    </tr>
  );
};
